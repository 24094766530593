:root {
    --margin-space: 20px;
}

section.FormSection {
     width: 60%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     max-width: 800px;
 }

section.FormSection form {
    width:100%;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

section.FormSection form div {
    background: #F7F7F7;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width:100%;
    margin: 40px;
    margin-bottom: 0px;
}

section.FormSection form div img {
    margin-right: 10px;
    padding-left: 10px;
}

section.FormSection form div input[type="text"] {
    border: none;
    width: 100%;
    background-color: #F7F7F7;
    font-size: 18px;
    color:#252525;
    height: 55px;
    margin-right: var(--margin-space);
}

section.FormSection form div input[type="text"]:focus {
    border: none !important;
    outline: none !important;
}

section.FormSection input[type="submit"] {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
    color: #7AB9FF;
    border: 1px solid #7AB9FF;
    padding: 8px;
    border-radius: 20px;
    margin-right: 10px;
}

section.FormSection form div input[type="submit"].desktopButton {
    display: block;
}

section.FormSection input[type="submit"].mobileButton {
    display: none;
}

section.FormSection input[type="submit"]:focus {
    outline: none !important;
    border: #7AB9FF;
    background: #7AB9FF;
    color: #F7F7F7;
}

section.FormSection form img.FormSectionLogoCross {
    border-radius: 20px;
    padding: 6px;
    border: 1px solid #B2B2B2;
    display: block;
}

@media screen and (max-width: 1024px){
    section.FormSection {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    section.FormSection {
        width: 95%;
    }

    section.FormSection form div input[type="text"] {
        font-size: 16px;
    }

    section.FormSection form div input[type="submit"].desktopButton {
        display: none;
    }

    section.FormSection input[type="submit"].mobileButton {
        display: block;
        margin: 30px;
    }
}

@media screen and (max-width: 640px){

}

@media screen and (max-width: 480px){

}