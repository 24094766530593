Section.ResultSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    max-width: 800px;
}

Section.ResultSection>p {
    width: 29vw;
    text-align: center;
    line-height: 1.5;
    font-size: 18px;
    font-family: Vollkorn, sans-serif;
}

Section.ResultSection>p.informationText {
    margin-top: 40px;
}

section.ResultSection div.contentAroundHorizontalResult {
    width: 140%;
    position: relative;
    margin-top: 0px;
}

.hideOverflowX {
    overflow-x: hidden !important;
}

section.ResultSection div.contentAroundHorizontalResult.marginTopArrow {
    margin-top: 50px;
}

section.ResultSection div.contentAroundHorizontalResult img.arrowRight {
    transform: scale(1.3);
    position: absolute;
    top: -30px;
    right: 15px;
    opacity: 0.2;
}


section.ResultSection section.HorizontalResult {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
}

section.ResultSection div.contentAroundHorizontalResult div.gradientLeft {
    background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0%, #ecebeb 100%);
    position: absolute;
    width: 66px;
    height: 100%;
    left: 0px;
    top: 0px;
    content: ' ';
    pointer-events: none;
}

section.ResultSection div.contentAroundHorizontalResult div.gradientRight {
    background: linear-gradient(270deg, #ecebeb 0%, rgba(247, 247, 247, 0) 100%);
    position: absolute;
    width: 66px;
    height: 100%;
    right: 0px;
    top: 0px;
    content: ' ';
    pointer-events: none;
}

section.RecommandationSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 20px;
    width: 100%;
    overflow: visible;
}

section.RecommandationSection div.selectedBook {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

section.RecommandationSection div.selectedBook img {
    height: 70px;
    width: 43px;
    margin-right: 20px;
}

section.RecommandationSection div.selectedBook div.DefaultCover {
    height: 70px;
    width: 43px;
    margin-right: 20px;
}

section.RecommandationSection div.selectedBook div.DefaultCover img {
    margin-right: 0px;
}

section.RecommandationSection div.selectedBook p:first-child {
    color: #535353;
    margin-bottom: 10px;
}

section.RecommandationSection div.selectedBook p:first-child span {
    color: #535353;
    margin-bottom: 10px;
    font-style: italic;
}

section.RecommandationSection div.selectedBook p:last-child {
    color: #252525;
    width: 100%;
    font-family: Vollkorn, sans-serif;
}

section.RecommandationSection aside h2 {
    margin-bottom: 2px;
}

section.RecommandationSection aside h3 {
    border-bottom: #E3E3E3 1px solid;
    margin-bottom: 12px;
    width: 100%;
}

section.upperSelectedBook {
    width: 100%;
}

section.upperSelectedBook button {
    border: none;
    outline: none;
    background: transparent;
    color: #B2B2B2;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
    cursor: pointer;
    margin: 20px 0px;
}

section.upperSelectedBook button img {
    margin-right: 10px;
}

@media screen and (max-width: 1024px){
    section.RecommandationSection {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    Section.RecommandationSection {
        width: 95%
    }

    Section.ResultSection p {
        width: 90%;
    }

    section.ResultSection {
        width: 100%;
    }

    section.ResultSection div.contentAroundHorizontalResult {
        width: 100%
    }


    section.ResultSection div.contentAroundHorizontalResult div.gradientLeft {
        width: 33px;
    }
    section.ResultSection div.contentAroundHorizontalResult div.gradientRight {
        width: 33px;
    }
}

@media screen and (max-width: 640px){

}

@media screen and (max-width: 480px){
    Section.ResultSection>p.informationText {
        margin-top: 20px;
    }
}
