:root {
    --margin-footer: 30px;
}

p a.legalMention {
    color: white;
    font-size: 8px;
    text-decoration: none;
    margin-left: 5px
}

footer.FooterNoReco {
    height: 50px;
    width: 100vw;
    background: #252525;
    flex-shrink: 0;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

footer.FooterNoReco > section.LeftPart {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: var(--margin-footer);
}

footer.FooterNoReco > section.LeftPart>p {
    color: #FFFFFF;
    margin-left: var(--margin-footer);
    opacity: 0.7;
    font-size: 14px;
}

footer.FooterNoReco > section.RightPart {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: var(--margin-footer);
    margin-right: var(--margin-footer);
    height: 100%;
}

footer.FooterNoReco > section.RightPart > div {
    height: 50%;
    width: 1px;
    background: #E3E3E3;
    margin : 0px calc(var(--margin-footer) / 2);
}


section.FooterWithReco > p.textOnMobileFooter {
    display: none;
}

section.FooterWithReco > section.FooterContent {
    width: 100%;
    background: #252525;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 200px;
    flex-wrap: wrap;
}

section.FooterWithReco > section.FooterContent > img {
    height: 600px;
    position: relative;
    top: -176px;
}

section.FooterWithReco > section.FooterContent > div {
    width: 50%;
}

section.FooterWithReco > section.FooterContent > div > p:first-child {
    width: 100%;
    position: relative;
    top: -90px;
    font-size: 23px;
    font-family: Vollkorn, sans-serif;
}

section.FooterWithReco > section.FooterContent > div > p:nth-child(2) {
    width: 100%;
    color: white;
}

section.FooterWithReco > section.FooterContent > div > div {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

section.FooterWithReco > section.FooterContent > div > div > a > img {
    width: 200px;
    margin-top: 20px;
}

section.FooterWithReco > section.FooterContent > div > div > a  > img:first-child {
    margin-right: 20px;
}

@media screen and (max-width: 1024px){
    div.CoreContent{
        width:100%;
    }
}

@media screen and (max-width: 768px) {
    div.CoreContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width: 640px){
    :root {
        --margin-footer: 20px;
    }

    section.FooterWithReco > p.textOnMobileFooter {
        display: block;
        align-self: center;
        text-align: center;
        font-family: Vollkorn;
        font-size: 20px;
        margin: 15px 10px 0px;
    }

    footer.FooterNoReco > section.LeftPart>p {
        font-size: 10px;
    }


    section.FooterWithReco > section.FooterContent > div {
        width: 50%;
        transform: translateY(-100px);
    }

    section.FooterWithReco > section.FooterContent > div > p:first-child {
        color: white;
        display: none;
    }

    section.FooterWithReco > section.FooterContent > div > div {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    section.FooterWithReco > section.FooterContent > div > div > a  > img {
        width: 200px;
        margin-bottom: 20px;
        margin-right: 0 !important;
    }
}

@media screen and (max-width: 480px){
    :root {
        --margin-footer: 10px;
    }
}