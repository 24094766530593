section.SearchResult {
    margin: 40px;
    margin-top: 15px;
    cursor: pointer;
    width: 125px;
}

section.SearchResult img {
    height: 200px;
    width: 125px;
}

section.SearchResult h2.title {
    margin-top: 10px;
}

section.SearchResult h3.author {
    margin-top: 0px;
}

@media screen and (max-width: 1024px){
}

@media screen and (max-width: 768px) {
    section.SearchResult {
        margin: 20px;
    }

    section.SearchResult h2.title {
        font-size: 14px;
    }

    section.SearchResult h3.author {
        font-size: 12px;
    }
}

@media screen and (max-width: 640px){
    section.SearchResult {
        margin: 20px;
    }

    section.SearchResult h2.title {
        font-size: 14px;
    }

    section.SearchResult h3.author {
        font-size: 12px;
    }
}

@media screen and (max-width: 480px){
    section.SearchResult {
        margin: 20px;
    }

    section.SearchResult h2.title {
        font-size: 14px;
    }

    section.SearchResult h3.author {
        font-size: 12px;
    }
}