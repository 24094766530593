div.CoreContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}

@media screen and (max-width: 1024px){
    div.CoreContent{
        width:100%;
    }
}

@media screen and (max-width: 768px) {
    div.CoreContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width: 640px){

}

@media screen and (max-width: 480px){

}