div#root > div {
  width: 100%;
  height: 100%;
}

.backgroundBlur {
  filter : blur(2px);
  z-index: 50;
  pointer-events: none;
}

.App {
  background-color: #F7F7F7;
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
}

div.cookieModal .minButton {
  color: #C4C4C4;
  margin-top: 25px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  border: none;
}
div.cookieModal .minButton:hover {
  box-shadow: none;
}

.App>div {
  background-color: #F7F7F7;
  flex: 1 1 auto;
}

div.behindModal {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.cookieModal {
  width: auto;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  padding: 80px;
  background: #F7F7F7;
  border-radius: 6px;
}

div.cookieModal a {
  text-decoration: none;
  color: #7AB9FF;
}


div.cookieModal > div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.cookieModal > div p {
  width: 50%;
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
}

div.cookieModal button {
  margin-top: 50px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  color: #7AB9FF;
  border: 1px solid #7AB9FF;
  padding: 8px;
  border-radius: 20px;
  margin-right: 10px;
}




.DefaultCover {
  width: 125px;
  height: 200px;
  background: #252525;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loadingAnimation {
  width: 50px;
  height: auto;
}

.DefaultCover>img{
  width:80% !important;
}

.shadowed {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.08);
}

.button {
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.08);
}

h2.title {
  font-family: Vollkorn, sans-serif;
  font-style: italic;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color:#000000;
  font-weight: normal;
}

h3.author {
  font-family: 'Fira Sans';
  color:#B2B2B2;
  font-size: 14px;
  font-weight: lighter;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 20px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

h3.edition {
  font-family: 'Fira Sans';
  color:#B2B2B2;
  font-size: 12px;
  font-weight: lighter;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 20px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.defaultTransformY {
  transform: translateY(10vh);
  animation-fill-mode: forwards;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
::-webkit-scrollbar-thumb:active {
  background: #4e4e4e;
}
::-webkit-scrollbar-track {
  background: #f7f7f7;
  border: 0px none #ffffff;
  border-radius: 43px;
}
::-webkit-scrollbar-track:hover {
  background: #f7f7f7;
}
::-webkit-scrollbar-track:active {
  background: #c5c5c5;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@keyframes translateYAfterForm {
  0% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

.upperPositionTransform {
  animation: translateYAfterForm 0.5s linear forwards;
}

.translateYTitle {
  transform: translate(0vh) scale(1);
  animation-fill-mode: forwards;
}

@keyframes translateYAfterSearchLogo {
  0% {
    transform: translateY(10vh) scale(1);
  }
  100% {
    transform: translateY(-1vh) scale(0.8);
  }
}

.upperPositionTransformLogo {
  animation: translateYAfterSearchLogo 0.5s linear forwards;
}

@keyframes opacityTitle {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.hideTitleTransform {
  animation: opacityTitle 0.5s linear forwards;
}

@media screen and (max-width: 768px) {
  h2.title {
    -webkit-line-clamp: 2;
  }

  div.cookieModal {
    padding: 20px;
  }

  div.cookieModal > div p {
    width: 90%;
  }

}