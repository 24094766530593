:root {
    --size-logo: 20px;
    --size-padding-logo: 8px;
    --margin-logo: 30px;
    --size-logo-min: 15px;
    --size-padding-logo-min: 8px;
    --margin-logo-min: 20px;
}

.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    height:auto;
    position:relative;
}

.Header div.SharePart p {
    margin-right: calc(var(--margin-logo) / 2);
    color: #B2B2B2;
}

.Header-logo:first-of-type {
    margin-right: calc(var(--margin-logo) / 2);
}

.Header-logo {
    padding: var(--size-padding-logo);
    height: var(--size-logo);
    width: var(--size-logo);
    border-radius: calc((var(--size-logo) + var(--size-padding-logo)*2) /2);
    margin-right: var(--margin-logo);
}

.Header-logo-facebook {
    background-color: #537BBE;
}

.Header-logo-twitter {
    background-color: #1DA1F2;
}

.Header-logo-LinkedIn {
    background-color: #006699;
}

div.GleephMainLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--margin-logo);
}

div.GleephMainLogo img:hover {
    box-shadow: none;
}

div.GleephMainLogo h1 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 19px;
    color: #595959;
    font-weight: normal;
    margin-bottom: 20px;
}

div.GleephMainLogo img {
    max-width: 600px;
}

header.Header div.SocialShare {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    margin-right: 30px;
    margin-top: 30px;
}

    header.Header div.SocialShare a.Header-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 15px;
    }

    header.Header div.SocialShare a.Header-logo:last-child {
        margin-right: 0px;
    }

@media screen and (max-width: 768px) {
    div.GleephMainLogo h1 {
        font-size: 14px;
    }

    div.GleephMainLogo img {
        width: 70%;
    }
}


@media screen and (max-width: 768px) {
    header.Header div.SocialShare {
        margin-top: 10px;
        margin-right: 10px;
    }
    .Header-logo {
        padding: var(--size-padding-logo-min);
        height: var(--size-logo-min);
        width: var(--size-logo-min);
        border-radius: calc((var(--size-logo-min) + var(--size-padding-logo-min)*2) /2)
    }
}