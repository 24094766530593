section.RecommandationResult {
    display: grid;
    flex-direction: row;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
    width: calc(100% - 40px);
    grid-template-columns: 125px;
    grid-template-areas:
            "cover infos infos infos"
            "cover resume resume resume"
            "cover button button .";
    column-gap: 15px
}

section.RecommandationResult>img{
    grid-area: cover;
    width: 125px;
    height: 200px;
}

section.RecommandationResult section {
    width:100%;
}

section.RecommandationResult section.sectionInfos {
    grid-area: infos;
}

section.RecommandationResult section.sectionResume {
    grid-area: resume;
    cursor: pointer;
}

section.RecommandationResult a {
    grid-area: button;
    align-self: flex-end;
    max-width: 168px;
    text-underline: transparent;
    text-decoration: none;
}

section.RecommandationResult p:first-child {
    width:100%;
    text-align: justify;
    font-size: 12px;
    margin-bottom: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 4;

    overflow: hidden;
    -webkit-box-orient: vertical;
    border-top: 1px solid #E3E3E3;
    padding-top: 10px;
    line-height: 140%;
}

section.RecommandationResult p:last-child {
    font-size: 12px;
    margin-bottom: 5px;
}


section.RecommandationResult p.seeMoreResume {
    display: block;
}

section.RecommandationResult p.seeMore {
    font-weight: bold;
    color: #B2B2B2;
}

section.RecommandationResult div.button {
    height:30px;
    outline: none;
    border: none;
    background: #FFAE4A;
    color: white;
    text-transform: uppercase;
    padding: 0px 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
}

section.RecommandationResult div.button:hover {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.08);
}

section.RecommandationResult div.button img {
    padding-right: 10px;
}

@media screen and (max-width: 768px) {
    section.RecommandationResult>img{
        grid-area: cover;
        height: 80px;
        width: 50px;
    }

    section.RecommandationResult {
        display: grid;
        flex-direction: row;
        background-color: #FFFFFF;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 40px;
        width: calc(100% - 40px);
        grid-template-areas:
                "cover infos infos infos"
                "resume resume resume resume"
                "button button . .";
        grid-template-columns: 50px;
        row-gap:15px;
        column-gap: 15px;
    }
}